import { Routes } from '@angular/router';
import { clusterRoutes } from './components/cluster/cluster.routes';
import { canActivateTeam } from './guards/home.guard';
import { userRouter } from './components/user/user.routing';
import { storeRoutes } from './components/store/store.routes';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./components/layout/layout.component').then((c) => c.LayoutComponent),
        canActivate: [canActivateTeam],
        children: [
            // {
            //     path : 'dashBoard',
            //     loadComponent : () => import('./components/dashboard/dashboard.component').then((c)=>c.DashboardComponent)
            // },
            {
                path: 'user',
                loadComponent: () => import('./components/user/user.component').then((c) => c.UserComponent),
                children: userRouter
            },
            {
                path: 'cluster',
                loadComponent: () => import('./components/cluster/cluster.component').then((c) => c.ClusterComponent),
                children: clusterRoutes

            },

            {
                path: "",
                redirectTo: 'user',
                pathMatch: "full"
            },

            {
                path: 'checkInList',
                loadComponent: () => import('./components/user/pages/check-in-details/check-in-details.component').then((c) => c.CheckInDetailsComponent)
            },
            {
                path: 'store',
                loadComponent: () => import('./components/store/store.component').then(m => m.StoreComponent),
                children: storeRoutes
            }

        ]
    },
    {
        path: 'login',
        loadComponent: () => import('./components/login/login.component').then((c) => c.LoginComponent)
    },
    // {
    //     path:'dasboard',
    //     loadComponent:()=> import('./components/dashboard/dashboard.component').then((c)=>c.DashboardComponent)
    // },


];
