import {Routes} from "@angular/router"

export const clusterRoutes : Routes = [
   
    {
        path : 'clusterList',
        loadComponent : ()=> import("./pages/cluster-listing/cluster-listing.component").then((c)=>c.ClusterListingComponent)
    },
    {
        path : '',
        redirectTo : 'clusterList',
        pathMatch : 'full'
    },
    {
        path : 'add-cluster',
        loadComponent : ()=> import("./pages/add-cluster/add-cluster.component").then((c)=>c.AddClusterComponent)
    }
        
]